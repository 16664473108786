@import 'variables.scss';

body {
  font-size: 1.4rem;
  line-height: 1.35;
  font-family: Open Sans, sans-serif !important;

  @include themedNoEncapsulation() {
    background-color: t('colour-e');
  }

  @include themedNoEncapsulation() {
    background: t('background');
    background-size: cover;
  }

  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: static;
  background-size: cover;
  height: 100%;
  margin: 0 !important;
}

@mixin lg-background {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  transition: all .3s;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.bg_login {
  @include lg-background;
}

.header_bar {
  height: 68px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 998;

  @include themedNoEncapsulation() {
    background-color: t('colour-header-background');
  }

  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  box-sizing: content-box;

  @media(max-width:600px) {
    height: $height-c*2;
    padding-bottom: 0;
  }
}

.AA-standard-grey {
  @include themedNoEncapsulation() {
    color: t('wcag-grey') !important;
  }

  opacity:1 !important;
}

.AA-standard-grey:hover {
  color: #686868 !important;
}

/*column layout classes START*/
.two-thirds-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  @media(max-width: $screen-a) {
    flex-direction: column;
  }

  & .two-third {
    width:calc(2/3*100% - (1 - 2/3)*#{$spacer-a});
    position: relative;

    @media(max-width: $screen-a) {
      width: 100%;
      margin: 0 0 calc($spacer-a / 2) 0;
    }
  }

  & .one-third {
    width:calc(1/3*100% - (1 - 1/3)*#{$spacer-a});
    position: relative;

    @media(max-width: $screen-a) {
      width: 100%;
      margin: 0 0 calc($spacer-a / 2) 0;
    }
  }

  &--ipad-break {
    @media(max-width: $ui-width-ipad) {
      flex-direction: column;

      & .one-third,
      .two-third {
        width: 100%;
      }
    }
  }
}

.thirds-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  @media(max-width: $screen-a) {
    flex-direction: column;
  }

  &>.thirds-block {
    width:calc(1/3*100% - (1 - 1/3)*#{$spacer-a});
    position: relative;

    @media(max-width: $screen-a) {
      width: 100%;
      margin: 0 0 calc($spacer-a / 2) 0;
    }

    &--center {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center !important;
    }
  }

  &--ipad-break {
    @media(max-width: $ui-width-ipad) {
      flex-direction: column;

      & .thirds-block {
        width: 100%;
      }
    }
  }
}

.thirds-container-hotel-search {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  @media(max-width: $screen-a) {
    flex-direction: column;
  }

  &>.thirds-block {
    width: calc(1/2*100% - (1 - 1/3)*#{$spacer-a});
    position: relative;

    @media(max-width: $screen-a) {
      width: 100%;
      margin: 0 0 calc($spacer-a / 2) 0;
    }

    &--center {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center !important;
    }
  }

  &>.half-block {
    width: calc(1/6*100% - (1 - 1/3)*15px);
    position: relative;

    @media(max-width: $screen-a) {
      width: 50% 1%;
      margin: 0 0 calc($spacer-a / 2) 0;
    }
  }

  &--ipad-break {
    @media(max-width: $ui-width-ipad) {
      flex-direction: column;

      & .thirds-block {
        width: 100%;
      }
    }
  }

}


.quarter-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  @media(max-width: $screen-a) {
    flex-direction: column;
  }

  &>.quarter-block {
    width:calc(1/4*100% - (1 - 1/4)*#{$spacer-a});
    position: relative;

    @media(max-width: $screen-a) {
      width: 100%;
      margin: 0 0 calc($spacer-a / 2) 0;
    }
  }

  &--ipad-break {
    @media(max-width: $ui-width-ipad) {
      flex-direction: column;

      & .quarter-block {
        width: 100%;
      }
    }
  }
}

.tenths-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  align-items: flex-end;

  @media(max-width: $screen-a) {
    flex-direction: column;
  }

  &--start {
    align-items: flex-start;
  }

  &>.tenth-block {
    width:calc(1/10*100% - (1 - 1/10)*#{$spacer-a});
    position: relative;
    display: flex;

    @media(max-width: $screen-a) {
      width: 100%;
      margin: 0 0 calc($spacer-a / 10) 0;
      ;
    }
  }

  &>.two-tenth-block {
    width:calc(2/10*100% - (1 - 2/10)*#{$spacer-a});
    position: relative;
    justify-content: center;
    display: flex;

    @media(max-width: $screen-a) {
      width: 100%;
      margin: 0 0 calc($spacer-a / 10) 0;
      ;
    }
  }

  &>.eight-tenth-block {
    width:calc(8/10*100% - (1 - 8/10)*#{$spacer-a});
    position: relative;
    justify-content: space-evenly;
    display: flex;

    @media(max-width: $screen-a) {
      width: 100%;
      margin: 0 0 calc($spacer-a / 10) 0;
      ;
    }

    &--center {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center !important;
    }

    &--start {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start !important;
    }

  }

  &>.nine-tenth-block {
    width:calc(9/10*100% - (1 - 9/10)*#{$spacer-a});
    position: relative;
    display: flex;

    @media(max-width: $screen-a) {
      width: 100%;
      margin: 0 0 calc($spacer-a / 10) 0;
      ;
    }
  }

  &--flexstart {
    align-items: flex-start;
  }

  @media(max-width:$screen-a) {
    flex-direction: column;
  }
}

.fifth-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  &>.fifth-block {
    width:calc(1/5*100% - (1 - 1/5)*#{$spacer-a});

    @media(max-width: $screen-a) {
      width: 100%;
      padding: 0 0 1% 0;
    }
  }

  &>.two-fifths-block {
    width:calc(2/5*100% - (1 - 2/5)*#{$spacer-a});

    @media(max-width: $screen-a) {
      width: 100%;
      padding: 0 0 1% 0;
    }
  }

  &>.three-fifths-block {
    width:calc(3/5*100% - (1 - 3/5)*#{$spacer-a});

    @media(max-width: $screen-a) {
      width: 100%;
      padding: 0 0 1% 0;
    }
  }

  &>.four-fifths-block {
    width:calc(4/5*100% - (1 - 4/5)*#{$spacer-a});

    @media(max-width: $screen-a) {
      width: 100%;
      padding: 0 0 1% 0;
    }
  }
}

.half-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  @media(max-width: $screen-a) {
    flex-direction: column;
  }

  &--start {
    align-items: flex-start;
  }

  &--tb-margin {
    margin: $spacer-a 0;
  }

  &>.half-block {
    width:calc(1/2*100% - (1 - 1/2)*#{$spacer-a});
    position: relative;

    @media(max-width: $screen-a) {
      width: 100%;
      margin: 0 0 calc($spacer-a / 2) 0;
      ;
    }
  }

  &--ipad-break {
    @media(max-width: $ui-width-ipad) {
      flex-direction: column;

      & .half-block {
        width: 100%;
      }
    }
  }
}

/*column layout classes END*/

/*Container and basket layout & container default colours START*/
.app {
  display: flex;
  justify-content: center;
  height: auto;
}

.app-body {
  display: flex;
  justify-content: space-between;
  max-width: calc(100% - 30px);
  height: 100%;
  width: 100%;
  @media(min-width: 1920px) {
    max-width: 1890px;
  }
  @media(max-width: $ui-width-a) {
    margin: $tablet-body-margin;
  }

  @media(max-width:$screen-a) {
    margin: $mobile-body-margin;
  }

  @media(max-width: 400px) {
    overflow-x: hidden;
  }
}

.app-left {
  max-width:calc(((100% - 15px) * 0.75));
  width: 100%;
  height: 100%;
  padding-top: $height-c*6;
  outline: none;

  @media(max-width: $ui-width-a) {
    max-width: 100%;
  }
}

.app-left-results {
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding-top: $height-c*6 + 62;
  outline: none;
}

.app-right {
  max-width:calc(((100% - 15px) * 0.25));
  width: 100%;
  height: 100%;
  padding-top: 88px;
  z-index: $z-index-h;
  outline: none;

  @media(max-width: $ui-width-a) {
    position: fixed;
    left: 100%;
    padding-top: 73px;
  }

  @media(max-width:calc(#{$ui-width-a}*#{$ui-ratio-b} - (#{$spacer-a}/2))) {
    width: calc(100% - 75px);
  }

  @media(max-width: 1200px) {
    max-width: calc((100% - 15px)* 0.33);
  }

   @media(max-width: 1024px) {
    max-width: calc((100% - 15px)* 0.4);
  }

  @media(max-width: 860px) {
    max-width: calc((100% - 15px)* 0.46);
  }

  @media(max-width: 767px) {
    max-width: calc(100vw - 75px);
    padding-top: 65px;
  }
}

.app-left-filters {
  max-width: 313px;
  width: 100%;
  height: 100%;
  padding: 15px;
  margin-top: 15px;
  z-index: $z-index-h;
  outline: none;
  margin-right: 30px;

  @media(max-width: 850px) {
    max-width: none !important;
  }
}

.filters-clear-top {
  margin-left: auto;
  font-size: 1.3rem;
  text-decoration: underline;
  align-self: flex-end;
  margin-bottom: 5px;
  cursor: pointer;
}

.app-right-basket {
  max-width:calc(#{$ui-width-a}*#{$ui-ratio-b} - (#{$spacer-a}/2));
  width: 100%;
  height: 100%;
  padding-top: 88px;
  z-index: $z-index-h + 2;
  outline: none;
  position: fixed;
  left: 100%;

  @media(max-width: $ui-width-a) {
    padding-top: 73px;
  }

  @media(max-width:calc(#{$ui-width-a}*#{$ui-ratio-b} - (#{$spacer-a}/2))) {
    width: calc(100% - 75px);
  }

  & .basket-button {
    display: block !important;
  }

  @media(max-width: 767px) {
    max-width: calc(100vw - 75px);
  }
}

.app-center {
  max-width:calc(#{$ui-width-a} - (#{$spacer-a}/2));
  width: 100%;
  height: 100%;
  padding-top: $height-c*6;
  z-index: $z-index-h;
  outline: none;

  &--padded {
    padding-left: $padding-a*4;
    padding-right: $padding-a*4;
  }
}

.app-basket-panel {
  height: auto;

  @include themedNoEncapsulation() {
    background-color: t('colour-h')
  }

  overflow-y: auto;
  overflow: hidden;
  max-height: calc(80vh - 50px);

  @media (max-width: 500px) {
    max-height: 50vh;
  }
}

.primary-text-colour {
  @include themedNoEncapsulation() {
    color: t('colour-font-primary');
  }
}

.secondary-text-colour {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary') !important;
  }
}

.tertiary-text-colour {
  @include themedNoEncapsulation() {
    color: t('colour-font-tertiary');
  }
}

.white_background {
  background: white !important;
}

.heart-img {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }
}

//quaternary
//quinary
//senary
//septenary
//octonary
//nonary
//denary

.basket-button {
  position: absolute;
  left: -$font-size-c*3;
  top: 88px;

  @include themedNoEncapsulation() {
    background-color: rgba(t('colour-a'), 1);
  }

  border-radius: $radius-a 0 0 $radius-a;
  padding: $font-size-c;
  border: none;
  font-size: $font-size-c;
  display: none;

  @include themedNoEncapsulation() {
    color: t('colour-b');
  }

  @media(max-width: $ui-width-a) {
    display: block;
    top: 73px;
  }

}

.search_content_titles {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }

  font-weight: 300;
  margin: 0 0 1rem;
  font-size: 21px;
  line-height: 28.35px;
  font-weight: 600;
  flex: 1 0 100%;
}

/*Container and basket layout & container default colours END*/


/*Flex row containers START*/
.flex-row-container {
  display: flex;
  flex-basis: auto;
  position: relative;

  &--center {
    justify-content: center;
  }

  &--around {
    justify-content: space-around;
  }

  &--evenly {
    justify-content: space-evenly;
  }

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &--between {
    justify-content: space-between;
  }

  &--v-center {
    align-items: center;
  }

  &--start {
    align-items: flex-start;
  }

  &--end {
    align-items: flex-end;
  }

  &--tb-margin {
    margin: calc($spacer-a / 2) 0;
  }

  &--wrap {
    flex-wrap: wrap;
    dt {
      margin-right: 5px;
    }
  }

  &--column-on-ipad {
    @media(max-width: $ui-width-ipad) {
      flex-direction: column;
    }
  }

  @media(max-width:$screen-a) {
    &--reset {
      justify-content: initial;
      align-items: initial;
    }
  }

  &--grow {
    flex-grow: 1;
  }

  &--shrink {
    flex-shrink: 1;
  }

  &--full-width {
    width: 100%;
  }

  &--white {
    @include themedNoEncapsulation() {
      background-color: t('colour-b');
    }
  }
}

.flex-column-mobile {
  @media(max-width: 850px) {
    flex-direction: column !important;
  }
}

.flex-column-container {
  display: flex;
  position: relative;
  flex-direction: column;

  &--tb-margin {
    margin: calc($spacer-a / 2) 0;
  }

  &--left {
    justify-content: left;
  }
}

/*Flex row containers END*/

/* Input Classes START*/
.header-label {
  margin: calc($spacer-a / 2) 0;

  &--no-margin {
    margin: 0;
  }

  &--bold {
    font-weight: bold;
  }
}

input[type=text],
select {
  /* Remove for ipad as it can only use a selector */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@mixin inputs-with-icons {
  border: none;
  height: $line-height-e;
  width: 100%;
  padding-left: $padding-a*6;
  font-size: $font-size-a;

  @include themedNoEncapsulation() {
    color: t('colour-a');
  }

  @include themedNoEncapsulation() {
    background-color: t('colour-b') !important;
  }

  border-radius: $radius-a;
  text-indent: 1px;
  text-overflow: '';
  z-index: auto;

  @include themedNoEncapsulation() {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAG0lEQVR42mNgwAfKy8v/48I4FeA0AacVDFQBAP9wJkE/KhUMAAAAAElFTkSuQmCC) calc(100% - 7px) 50% no-repeat t('colour-b');
  }

  &::placeholder {
    @include themedNoEncapsulation() {
      color: t('wcag-grey');
    }
  }

  &.disabled {
    @include themedNoEncapsulation() {
      background-color: t('colour-disabled') !important;
    }

  }

  &.ng-invalid {
    @include themedNoEncapsulation() {
      background-color: lighten(t('colour-alert'), 40%) !important;
    }

    @include themedNoEncapsulation() {
      border-color: t('colour-alert');
    }

    &::placeholder {
      @include themedNoEncapsulation() {
        color: t('wcag-grey');
      }
    }
  }

  & option {
    @include themedNoEncapsulation() {
      background-color: t('colour-b') !important;
    }
  }

  &-left {
    width: 50%;
    border-radius: $radius-a 0 0 $radius-a;
  }

  &-right,
  &-right.ng-invalid {
    width: 50%;
    border-radius: 0 $radius-a $radius-a 0;

    @include themedNoEncapsulation() {
      border-left: 1px solid t('colour-e');
    }
  }

  &-icon {
    position: absolute;
    bottom: calc($font-size-a / 1.5);
    left: 0;
    width: 34px;

    @include themedNoEncapsulation() {
      color: t('colour-a');
    }

    z-index: 9;
    text-align: center;
    font-size: $font-size-a;
  }

  &-icon--right {
    position: absolute;
    bottom: calc($font-size-a / 1.5);
    left: 50%;
    width: 34px;

    @include themedNoEncapsulation() {
      color: t('colour-a');
    }

    z-index: 9;
    text-align: center;
    font-size: $font-size-a;
  }

  &--no-icon {
    padding-left: $padding-a*2;
  }  
  
  &--no-background {
    background: none !important;
  }

  &--border {
    @include themedNoEncapsulation() {
      border: $border-width-a solid t('colour-c') !important;
    }
  }

  &--no-caret {
    padding-left: $padding-a;
    padding-right: $padding-a;
    background: none !important;
  }
}

@mixin inputs-no-icons {
  border: none;
  height: $line-height-e;
  width: 100%;
  padding-left: $padding-a*6;
  font-size: $font-size-a;

  @include themedNoEncapsulation() {
    color: t('colour-a');
  }

  @include themedNoEncapsulation() {
    background-color: t('colour-b') !important;
  }

  border-radius: $radius-a;
  text-indent: 1px;
  text-overflow: '';
  z-index: auto;

  &::placeholder {
    @include themedNoEncapsulation() {
      color: t('wcag-grey');
    }
  }

  &.disabled {
    @include themedNoEncapsulation() {
      background-color: t('colour-disabled') !important;
    }

  }

  &.ng-invalid {
    @include themedNoEncapsulation() {
      background-color: lighten(t('colour-alert'), 40%) !important;
    }

    @include themedNoEncapsulation() {
      border-color: t('colour-alert');
    }

    &::placeholder {
      @include themedNoEncapsulation() {
        color: t('wcag-grey');
      }
    }
  }

  & option {
    @include themedNoEncapsulation() {
      background-color: t('colour-b') !important;
    }
  }

  &-left {
    width: 50%;
    border-radius: $radius-a 0 0 $radius-a;
  }

  &-right,
  &-right.ng-invalid {
    width: 50%;
    border-radius: 0 $radius-a $radius-a 0;

    @include themedNoEncapsulation() {
      border-left: 1px solid t('colour-e');
    }
  }

  &-icon {
    position: absolute;
    bottom: calc($font-size-a / 1.5);
    left: 0;
    width: 34px;

    @include themedNoEncapsulation() {
      color: t('colour-a');
    }

    z-index: 9;
    text-align: center;
    font-size: $font-size-a;
  }

  &-icon--right {
    position: absolute;
    bottom: calc($font-size-a / 1.5);
    left: 50%;
    width: 34px;

    @include themedNoEncapsulation() {
      color: t('colour-a');
    }

    z-index: 9;
    text-align: center;
    font-size: $font-size-a;
  }

  &--no-icon {
    padding-left: $padding-a*2;
  }  
  
  &--no-background {
    background: none !important;
  }

  &--border {
    @include themedNoEncapsulation() {
      border: $border-width-a solid t('colour-c') !important;
    }
  }

  &--no-caret {
    padding-left: $padding-a;
    padding-right: $padding-a;
    background: none !important;
  }
}

.combo-box,
input.combo-box {
  @include inputs-with-icons;
}

.select-box {
  @include inputs-with-icons;

  height: 3.4rem !important;

  &::-ms-expand {
    display: none;
  }
}

.form_validation {
  padding: 1.5rem 2rem;
  height: auto;
  min-height: inherit;

  @include themedNoEncapsulation() {
    background: t('colour-d');
  }

  margin: 0 0 2rem;
  border-radius: .3rem;
  position: relative;
}

.error_validation_close {
  position: absolute;
  top: 0;
  right: 0;
  background: none;

  @include themedNoEncapsulation() {
    color: t('colour-b');
  }

  border: none;
  height: 20px;
}

.validation_error {
  @include themedNoEncapsulation() {
    color: t('colour-b');
  }

  opacity: 1;
  display: block;
  font-weight: 800;
  font-size: 14px;
  line-height: 18.9px;
}

.error_message {
  @include themedNoEncapsulation() {
    color: t('colour-alert');
  }
}

.full_width {
  width: 100%;
}

.center_text {
  text-align: center !important;
}

.select-box:disabled {
  @include themedNoEncapsulation() {
    background-color: t('colour-disabled-light') !important;
  }

  cursor: not-allowed;
}

.button-box {
  @include inputs-with-icons;
  background: none;
  border: none;
  width: 100%;
  border-radius: $radius-a 0 0 $radius-a;
}

.input-box {
  @include inputs-no-icons;

  &-left {
    border-radius: $radius-a 0 0 $radius-a;


    @include themedNoEncapsulation() {
      border-right: 1px solid t('colour-f');
    }

    &--no-border {
      border-right: none;
    }

    @media(max-width:$screen-a) {
      border-radius: $radius-a;
      border-right: none;
    }
  }

  &-right {
    border-radius: 0 $radius-a $radius-a 0;

    @media(max-width: $screen-a) {
      border-radius: $radius-a;
      border-left: none;
    }
  }

  &--no-iconed {
    padding-left: 5px;
  }
}

.input-box:disabled {
  @include themedNoEncapsulation() {
    background-color: t('colour-disabled');
  }

  cursor: not-allowed;
}

.input-loading-spinner {
  &-left {
    position: absolute;
    top: calc($line-height-a / 2);
    left: 40%;
  }

  &-right {
    position: absolute;
    top: calc($line-height-a / 2);
    left: 90%;
  }
}

/* Input Classes END*/


/* Button Classes START*/
@mixin button-a {
  min-width: $width-a;
  max-height: $line-height-e;
  padding: $padding-a*2;
  line-height: $line-height-a;
  vertical-align: bottom;
  font-size: $font-size-a;

  @include themedNoEncapsulation() {
    background: t('colour-d');
  }

  transition: .3s;

  @include themedNoEncapsulation() {
    color: t('colour-button-text');
  }

  cursor: pointer;
  border: none;
  border-radius: $radius-a;
  font-weight: bold;

  &:hover {
    @include themedNoEncapsulation() {
      background: lighten(t('colour-d'), 10%);
    }
  }

  @media screen and (-ms-high-contrast: active) {
    border: 1px solid #1aebff !important;

    &:hover,
    &:focus {
      color: #1aebff;
      border: 2px solid #1aebff;
    }
  }
}

.small-button {
  @include button-a;

  @include themedNoEncapsulation() {
    color: t('colour-button-text');
  }

  &--left-margin {
    margin-left: calc($spacer-a / 2);
  }

  &--right-margin {
    margin-right: calc($spacer-a / 2);
  }

  &--margin {
    margin: calc($spacer-a / 2);
  }

  &--close {
    background: #EB0000 !important;
  }

  &--no {
    @include themedNoEncapsulation() {
      background-color: t('colour-c');
    }
  }

  &--reduced-text {
    font-size: $font-size-a*0.86;
  }

  &--flex {
    display: flex;
    align-items: center;
  }

  &:disabled {
    @include themedNoEncapsulation() {
      background-color: t('colour-disabled');
    }

    cursor: not-allowed;
  }

  &--mobile-margin {
    @media (max-width: $screen-a) {
      margin: calc($spacer-a / 2) calc($spacer-a / 2) calc($spacer-a / 2) 0;
    }
  }
}

.box-button {
  @include button-a;
  min-width: calc($width-a / 2);

  &-left {
    border-radius: $radius-a 0 0 $radius-a;
  }

  &-right {
    border-radius: 0 $radius-a $radius-a 0;
  }

  &--no-radius {
    border-radius: 0;
  }
}

.large-button {
  min-width: $width-d;
  margin-top: $spacer-a;
  padding: 0 $padding-a*4;
  line-height: $line-height-e;
  height: $line-height-e*1.1;
  vertical-align: bottom;
  font-size: $font-size-c;
  transition: .3s;

  @include themedNoEncapsulation() {
    background: t('colour-d');
  }

  @include themedNoEncapsulation() {
    color: t('colour-button-text');
  }

  cursor: pointer;
  border: none;
  border-radius: $radius-a;
  font-weight: bold;

  &--search:hover {
    @include themedNoEncapsulation() {
      box-shadow: transparentize(t('colour-b'), 0.2) 0 0 0 0.25rem;
    }

    opacity: 1;
    z-index: $z-index-g;
  }

  &:disabled {
    @include themedNoEncapsulation() {
      background-color: t('colour-c');
    }
  }

  &--max-width {
    width: 100%;
  }

  &--no-margin {
    margin: 0;
  }

  &--margin {
    margin: calc($spacer-a / 2);
  }

  @media screen and (-ms-high-contrast: active) {
    border: 1px solid #1aebff !important;

    &:hover,
    &:focus {
      color: #1aebff;
      border: 2px solid #1aebff;
    }
  }
}

.triple-radio-buttons {
  display: flex;
  flex-wrap: wrap;

  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }

  &>.mat-mdc-radio-button {
    width: auto;
    flex: 0 1 33.333%;
    padding-right: $font-size-a;

    &:last-child {
      padding-right: 0;
    }

    @media (max-width:$screen-a) {
      justify-content: left;
      flex: 0 1 45%;

      &:first-child {
        flex: 0 1 100%;
        justify-content: left;
      }
    }
  }
}

.quad-radio-buttons {
  display: flex;
  flex-wrap: wrap;

  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }

  &>.mat-mdc-radio-button {
    width: auto;
    flex: 0 1 25%;
    padding-right: $font-size-a;

    @media (max-width: $screen-a) {
      justify-content: left;
      flex: 0 1 50%;
    }
  }
}

.quin-radio-buttons {
  display: flex;
  flex-wrap: wrap;

  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }

  &>.mat-mdc-radio-button {
    width: auto;
    flex: 0 1 20%;
    padding-right: $font-size-a;

    @media (max-width: $screen-a) {
      justify-content: left;
      flex: 0 1 50%;
    }
  }
}

.button_spinner {
  border: 2px solid hsla(0, 0%, 100%, .2);

  @include themedNoEncapsulation() {
    border-left: 2px solid t('colour-d');
  }

  border-radius: 50%;
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  margin: .2rem auto;
  transform: translateZ(0);
  animation: spinner .5s infinite linear;
  transition: all 2s ease-out;
}

/* Button Classes END*/

/* Background colour START*/
::ng-deep {
  @for $i from 1 through 100 {
    .background-#{$i} {
      @include themedNgDeep() {
        @if (t('seethrough-background')) {
          background-color: rgba(t('colour-container-background'), calc($i / 100)) !important;
        }
      }
    }

    //Theming solid background
    .theme--navy-theme .background-#{$i} {
      background-color: rgb(0, 48, 67) !important;
    }

    .theme--navy-theme .secondary-text-colour .background-#{$i} {
      color: white !important;

      & .booking_option_toggle {
        border: 2px solid white !important;
      }

      & .booking_option_toggle {
        border: 2px solid white !important;
      }

      & a.guest_traveller_link {
        color: white !important;
      }

      & .triple-radio-buttons {
        color: white !important;
      }

      & .recent-search-item .icons i {
        color: white !important;
      }

      & .recent-search-item .details {
        color: white !important;
      }

      & .recent-search-item .Traveller {
        color: white !important;
      }

      & .label.mat-mdc-radio-label {
        color: white !important;
      }

      & .quin-radio-buttons {
        color: white !important;
      }

      & .mat-mdc-radio-button.mat-primary .mdc-radio__outer-circlee {
        border-color: white !important;
      }

      & .mat-mdc-radio-button.mat-primary .mdc-radio__inner-circle {
        background-color: white !important;
      }

      & .light-primary .mat-mdc-checkbox-frame {
        border-color: white !important;
      }

      & .mat-mdc-checkbox-checked.mat-primary .mat-mdc-checkbox-background {
        background-color: white !important;
      }

      & .mat-mdc-checkbox-checkmark {
        fill: rgb(0, 48, 67) !important;
      }

      & .mat-mdc-checkbox-checkmark-path {
        stroke: rgb(0, 48, 67) !important;
      }

      & .ngx-slider-bubble {
        color: white !important;
      }

      & .sort_by_options li {
        color: white !important;
      }

      & .guest_back_arrow {
        color: white !important;
      }

      & .no_results_icon {
        color: white !important;
      }

      & .no_results_message_heading {
        color: white !important;
      }

      & .basket-item-count {
        color: white !important;
      }

      & .custom_ripple {
        color: white !important;
      }

      & .sorting-toggle_list-item {
        color: white !important;
      }

      & .header-label {
        color: white !important;
      }
    }

    & .theme--navy-theme {
      & app-main .nav-search {
        background: #003043;
      }

      & .sort_by_options li {
        color: white !important;
      }

      & .hotel_filter_options {
        color: white !important;

        & .filter-columns mat-checkbox.mat-primary .mat-mdc-checkbox-frame {
          border-color: white !important;
        }
      }

      & .filter_options {
        & .filter_options_cols mat-checkbox.mat-primary .mat-mdc-checkbox-frame {
          border-color: white !important;
        }

        & h4 {
          color: white !important;
        }

        & .ngx-slider .ngx-slider-bubble {
          color: white !important;
        }
      }

      & .sorting-toggle {
        background: #003043 !important;
        color: white !important;
      }
    }

    .theme--scion .custom_icon-season-tickets{
      filter: invert(1);
    }

    .theme--blue-theme .custom_icon-season-tickets{
      filter: invert(1);
    }
  }
}


/* Background colour END*/

/*Custom search radio buttons START*/
::ng-deep .booking_options_option .mat-mdc-form-field {
  @media screen and (-ms-high-contrast: active) {
    opacity: 0;
  }
}

::ng-deep .mat-mdc-checkbox-label:focus {
  @media screen and (-ms-high-contrast: active) {
    @include themedNoEncapsulation() {
      border: 1px solid t('colour-font-secondary');
    }
  }
}

.booking_options_option--long label i {
  display: auto;
  font-size: 30px;
  margin-right: 8px;
  vertical-align: baseline;
  line-height: 2pc;
}

.booking_option_toggle {
  width: 25px;
  height: 25px;
  min-width: 25px;
  background: transparent;
  border-radius: 20px;

  @include themedNoEncapsulation() {
    border: 2px solid t('colour-font-secondary');
  }

  display: inline-block;
  top: 2px;
  position: relative;
  margin-right: 10px;
  transition: all ease-out .1s;
  text-align: center;
  line-height: 1pc;
  cursor: inherit;

  @media (min-width: 400px) {
    margin-right: 5px;
  }

  &_active {
    @include themedNoEncapsulation() {
      background-color: t('colour-d') !important;
    }

    cursor: inherit;
  }

  &_check {
    text-transform: none !important;
    line-height: 1;
    vertical-align: middle;
    transition: all ease-out .1s;
    position: absolute;
    top: 1rem;
    opacity: 0;
    cursor: inherit;
    animation: fadeOutDown .2s;
    left: .3rem;
    opacity: 0;
    font-size: 1.4rem !important;

    &_active {
      position: absolute;
      opacity: 1;
      top: 0.2rem;
      left: .3rem;
      cursor: inherit;
      animation: fadeInUp .2s;
      font-size: 1.4rem !important;

      @include themedNoEncapsulation() {
        color: t('colour-button-text');
      }
    }
  }
}

.booking_options_option--long .booking_option_toggle {
  top: auto;
  width: 3.4rem;
}

.booking_options_option:hover .booking_option_toggle {
  @include themedNoEncapsulation() {
    background-color: rgba(t('colour-d'), .3) !important;
  }
}

.booking_options_others:hover .booking_others_toggle {
  @include themedNoEncapsulation() {
    background-color: rgba(t('colour-d'), .3) !important;
  }
}

.booking_options_option:hover .others-container .booking_option_toggle {
  background-color: initial !important;
}

/* Button Classes END*/

/*****************************************
* Start of slider styles                 *
*****************************************/

.custom-slider .ngx-slider .ngx-slider-bar {
  @include themedNoEncapsulation() {
    background: t('colour-font-secondary');
  }

  height: 2px;

  @media screen and (-ms-high-contrast: active) {
    @include themedNoEncapsulation() {
      background: t('colour-font-secondary');
    }
  }
}

.custom-slider .ngx-slider .ngx-slider-selection {
  @include themedNoEncapsulation() {
    background: t('colour-d');
  }
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  width: $font-size-c;
  height: $font-size-c;
  top: -9px;
  /* to remove the default positioning */
  bottom: 0;

  @include themedNoEncapsulation() {
    background-color: t('colour-d');
  }

  border-radius: calc(calc($font-size-c / 2));

  @media screen and (-ms-high-contrast: active) {
    @include themedNoEncapsulation() {
      background-color: t('colour-d');
    }
  }
}

.ngx-slider-bubble {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary') !important;
  }
}

.custom-slider .ngx-slider .ngx-slider-pointer:after {
  top: $radius-b;
  left: $radius-b;
}

/*****************************************
* End of slider styles                   *
*****************************************/
/*Custom class to open admin at a taller height START*/
::ng-deep .extra-large {

  & .modal-content,
  & .modal-body {
    height: 100%;
    max-height: initial;
  }

  & .modal-dialog {
    height: auto;
    max-height: initial;
  }
}

/*Custom class to open admin at a taller height END*/

@media not speech {
  .speech-only {
    display: none;
  }
}

.user-is-tabbing *:focus {
  outline: 2px solid #BFD6FD !important;
  box-shadow: 0px 0px 4px #BFD6FD !important;
}

.user-is-tabbing .mat-expansion-panel-header:focus {
  outline: 2px solid #BFD6FD !important;
  box-shadow: inset 0px 0px 4px #BFD6FD !important;
}

.user-is-tabbing .tab_base:focus {
  outline: 2px solid #BFD6FD;
  outline-offset: -2px
}

.user-is-tabbing .mat-mdc-radio-container:focus-within .mat-mdc-ripple {
  outline: 2px solid #BFD6FD !important;
  outline-offset: -10px;
}

.user-is-tabbing .mdc-checkbox:focus-within {
  outline: 2px solid #BFD6FD !important;
  outline-offset: 2px;
}

.user-is-tabbing input:focus {
  outline-color: #516DDB !important;
  box-shadow: 0px 0px 4px #516DDB !important;
}

.user-is-tabbing select:focus {
  outline-color: #516DDB !important;
  box-shadow: 0px 0px 4px #516DDB !important;
}

.user-is-tabbing .travellers_container:focus {
  outline-color: #516DDB !important;
  box-shadow: 0px 0px 4px #516DDB !important;
}

.user-is-tabbing .locations_link:focus {
  outline-color: #516DDB !important;
  box-shadow: 0px 0px 4px #516DDB !important;
}

.user-is-tabbing .traveller_placeholder:focus {
  outline-color: #516DDB !important;
  box-shadow: 0px 0px 4px #516DDB !important;
}

.user-is-tabbing .mat-mdc-dialog-container *:focus {
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing app-item-flights *:focus {
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing app-item-hotels *:focus {
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing .car_item *:focus {
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing .pnl-item *:focus {
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing .eurostar-list_item *:focus {
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing .taxi-item *:focus {
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing app-itinerary .booking_container *:focus {
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing .mat-mdc-dialog *:focus {
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing .modal-dialog *:focus {
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing app-my-bookings-basket-card:focus {
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing .selected_travellers li *:focus{
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing .additional_passeger_message:focus{
  outline-color: black !important;
  box-shadow: 0px 0px 4px black !important;
}

.user-is-tabbing .recomended-option:focus {
  outline-color: #516DDB !important;
  box-shadow: 0px 0px 4px #516DDB !important;
}

.user-is-tabbing .recomended-option.selected:focus {
  outline: 2px solid #BFD6FD !important;
  box-shadow: 0px 0px 4px #BFD6FD !important;
}

*:focus {
  outline: none;
}

.ie-width-fix img {
  width: 150px;
}

.top-32 {
  top: 32px;
}

.top-230 {
  top: 230% !important;
}

.fare-rules-warning {
  @include themedNoEncapsulation() {
    color: t('colour-alert');
  }

  margin-bottom:5px;
}

.btm-margin-5 {
  margin-bottom: 5px;
}

.colour-font-secondary {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary') !important;
  }
}

.background-colour-colour-b {
  @include themedNoEncapsulation() {
    background-color: t('colour-b') !important;
  }
}

.rail_search_tab_active {
  @include themedNoEncapsulation() {
    //color: t('colour-font-secondary') !important;
    color: #ffffff !important;
    background-color: #003043 !important;
  }
}

.office_search {
  @include themedNoEncapsulation() {
    background: t('colour-b') !important;
  }
}

.mat-expansion-panel.basketpanel {
  @include themedNoEncapsulation() {
    border: 1px solid t('colour-font-secondary');
  }
}

.details {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }
}

.icons i {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }
}

.itemContainer {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }
}

.dateLabel {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }
}

.radio_type_label .checkMark:after {
  @include themedNoEncapsulation() {
    background: t('colour-button-text');
  }
}

.noUi-tooltip {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }
}

.slide_button {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }
}

.booking_panel_options .mat-mdc-tab-label-active {
  @include themedNoEncapsulation() {
    background-color: t('colour-d');
  }
}

.booking_panel_options .mat-mdc-tab-group.mat-primary .mat-mdc-tab-label:not(.mat-mdc-tab-disabled):focus,
.mat-mdc-tab-group.mat-primary .mat-mdc-tab-link:not(.mat-mdc-tab-disabled):focus,
.mat-mdc-tab-nav-bar.mat-primary .mat-mdc-tab-label:not(.mat-mdc-tab-disabled):focus,
.mat-mdc-tab-nav-bar.mat-primary .mat-mdc-tab-link:not(.mat-mdc-tab-disabled):focus {
  @include themedNoEncapsulation() {
    background-color: t('colour-d');
  }
}

.traveller_input>input:focus {
  @include themedNoEncapsulation() {
    border-color: t('colour-d');
  }
}

.traveller_options>li:first-child {
  @include themedNoEncapsulation() {
    background: t('colour-d');
  }
}

.active_traveller_result {
  @include themedNoEncapsulation() {
    background: t('colour-d');
  }
}

.location_search:focus-within {
  @include themedNoEncapsulation() {
    border: 2px solid t('colour-d');
  }
}

/* When the radio button is checked, add a blue background */

.radio_type_label input:checked~.checkMark {
  @include themedNoEncapsulation() {
    background-color: t('colour-d');
  }
}

.modal_ok {
  @include themedNoEncapsulation() {
    background: t('colour-d');
  }
}

.search_button {
  @include themedNoEncapsulation() {
    background: t('colour-d');
  }
}

.small_search_button {
  @include themedNoEncapsulation() {
    background: t('colour-d');
  }
}

.tab_cheapest {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }
}

.currency_code {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }
}

.hotel_item_add_to_cart {
  @include themedNoEncapsulation() {
    background: t('colour-d');
  }
}

.hotel_list_currency_code_inner {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }
}

.hotel_list_item_tab_active {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }
}

.hotel_list_item_tab:hover {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }
}

.general_tab_details {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }
}

.hotel_list_item_add {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }
}

.hotel_list_item_adder a {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }
}

.hotel_facility_list li:before {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }
}

.hotel_grid_display_rate_price {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }
}

.hotel_grid_currency_code {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }
}

.radius_list_active {
  @include themedNoEncapsulation() {
    background: t('colour-d');
  }
}

ngb-typeahead-window.dropdown-menu:first-child {
  @include themedNoEncapsulation() {
    background-color: t('colour-d') !important;
  }
}

.noUi-connect {
  @include themedNoEncapsulation() {
    background: t('colour-d');
  }
}

.options-row .form-group>select {
  @include themedNoEncapsulation() {
    color: t('colour-f');
  }
}

.radius_select {
  @include themedNoEncapsulation() {
    color: t('colour-f');
  }
}

.radius_select_open {
  @include themedNoEncapsulation() {
    color: t('colour-f');
  }
}

.slide_button {
  @include themedNoEncapsulation() {
    background-color: t('colour-h');
  }
}

.basket_container {
  @include themedNoEncapsulation() {
    background-color: t('colour-h');
  }
}

.select-container {
  @include themedNoEncapsulation() {
    background-color: t('light-gray');
  }
}

.pricePill {
  @include themedNoEncapsulation() {
    color: t('wcag-grey');
  }
}

.search-extras.mat-mdc-active {
  @include themedNoEncapsulation() {
    background: t('wcag-grey') !important;
  }
}

.search-extras:hover,
.search-extras:focus {
  @include themedNoEncapsulation() {
    background: t('wcag-grey') !important;
  }
}

.user-is-tabbing .search-extras:focus {
  @include themedNoEncapsulation() {
    background: t('wcag-grey') !important;
  }
}

.ico-exclaim-1 {
  @include themedNoEncapsulation() {
    color: t('colour-alert');
  }
}

.ico-exclamation {
  @include themedNoEncapsulation() {}
}

.extraDetails {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

:host-context(.maxtabs_1) .extraDetails,
:host-context(.maxtabs_2) .extraDetails {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.leg_container strong {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.leg_container i {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.leg_header {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.leg_start_location {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.leg_end_location {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.legs_layover i {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.time_select {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.location_search {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.date_form {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.form_date_input {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.form_date_input_right {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.form_date_input_alone {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.dropdown-menu {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.resolve_loader_loading_container {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.search_date_picker_input {
  @include themedNoEncapsulation() {
    color: t('colour-a');
  }
}

.rooms_input_container>.ico {
  @include themedNoEncapsulation() {
    color: t('colour-a') !important;
  }
}

.ngb-dp-weekday {
  @include themedNoEncapsulation() {
    color: t('colour-a') !important;
  }
}

.search-extras {
  @include themedNoEncapsulation() {
    color: t('colour-b') !important;
  }
}

.search-extras.mat-mdc-active {
  @include themedNoEncapsulation() {
    color: t('colour-b') !important;
  }
}

.search-extras.mat-mdc-active {
  @include themedNoEncapsulation() {
    color: t('colour-b') !important;
  }
}

.search-extras:hover {
  @include themedNoEncapsulation() {
    color: t('colour-b') !important;
  }
}

.alt_colour {
  @include themedNoEncapsulation() {
    color: t('colour-b') !important;
  }
}

.border {
  @include themedNoEncapsulation() {
    border-top: 1px solid t('colour-b') !important;
  }
}

.flight_ticket_table tr:nth-child(even) {
  @include themedNoEncapsulation() {
    background-color: t('colour-b') !important;
  }
}

.location_input {
  @include themedNoEncapsulation() {
    background-color: t('colour-b') !important;
  }
}

.ico-exclaim-1--strong {
  @include themedNoEncapsulation() {
    color: t('light-warning') !important;
  }
}

.mat-primary .mat-mdc-pseudo-checkbox-checked {
  @include themedNoEncapsulation() {
    color: t('colour-button-text') !important;
    background: t('colour-d') !important;
  }
}

.mat-mdc-radio-button.mat-primary .mdc-radio__inner-circle {
  @include themedNoEncapsulation() {
    background-color: t('colour-d') !important;
  }
}

.mat-mdc-radio-button.mat-primary .mdc-radio__outer-circle {
  @include themedNoEncapsulation() {
    border-color: t('colour-d') !important;
  }
}

.mat-mdc-checkbox-checked.mat-primary .mat-mdc-checkbox-background {
  @include themedNoEncapsulation() {
    background-color: t('colour-d') !important;
  }
}

.no_results_message_heading {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }
}

.no_results_message {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }
}

.parking-return-calendar {
  left: calc(12% - 1px);
}

.hotel_list_item_extra_detail {
  @include themedNoEncapsulation() {
    background: t('colour-b');
  }
}

.modal_button_okay:hover {
  @include themedNoEncapsulation() {
    background: lighten(t('colour-d'), 10%);
  }

  ;
}

#lefteye,
#righteye,
#head {
  @include themedNoEncapsulation() {
    fill: t('colour-button-text');
  }
}

.mat-mdc-ripple-element {
  @include themedNoEncapsulation() {
    background-color: t('colour-d') !important;
  }
}

.home-nav li a:hover,
.home-nav li>span:hover {
  @include themedNoEncapsulation() {
    box-shadow: rgba(t('colour-b'), .8) 0 0 0 .25rem;
  }
}

.seat-leg {
  height: 100%;
  display: block;
}

.seat-map-header {
  font-weight: bold;
  margin-bottom: 15px;
}

.user_dropdown_info p {
  @include themedNoEncapsulation() {
    color: t('colour-b') !important;
  }
}

.languageChooserDialog__item:hover {
  cursor: pointer;
}

body .filterOptionsDialog {
  @include themedNoEncapsulation() {
    background: t('colour-sort-filter');
  }
}

.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.wcag-checkbox {
  & .mat-mdc-radio-button.mat-primary .mdc-radio__outer-circle {
    @include themedNoEncapsulation() {
      border-color: t('colour-font-secondary') !important;
    }
  }

  & .mat-mdc-radio-button.mat-primary .mdc-radio__inner-circle {
    @include themedNoEncapsulation() {
      border-color: t('colour-font-secondary') !important;
    }
  }

  & .light-primary .mdc-checkbox__background {
    @include themedNoEncapsulation() {
      border-color: t('colour-font-secondary') !important;
    }
    top: 0;
    left: 0;
  }

  & .mat-mdc-checkbox-checked.mat-primary .mdc-checkbox__background {
    @include themedNoEncapsulation() {
      background-color: t('colour-font-selected') !important;
      border-color: t('colour-font-selected') !important;
    }
  }

  & .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    @include themedNoEncapsulation() {
      color: t('colour-d') !important;
    }
  }

  & .mat-mdc-checkbox-checkmark-path {
    @include themedNoEncapsulation() {
      stroke: t('colour-d') !important;
    }
  }
}

.rem-1-5 {
  font-size: 1.5rem !important;
}

body .black-label-radio .mat-mdc-radio-label-content {
  color: black !important;
}

body .black-label-radio.mat-mdc-radio-disabled .mat-mdc-radio-label-content {
  color: #747474 !important;
}

.container {
  display: contents;
}

.hidden-range-inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  height: 0;
  overflow: hidden;

  &--focused {
    padding-bottom: 10px;
    height: initial;
  }
}

.hidden-range-input {
  display: flex;
  width: 30%;
  border-radius: 3px;
  margin: 2px;
  border: 2px solid rgba(0, 0, 0, 0);
}

.invalidKeyboardSlider {
  border: 2px solid red;
}

.leg_time {
  @media(max-width: 1600px) {
    padding-right: 55px !important;
  }
}

.fare-rules-link-text {
  margin-left: 5px;
  margin-right: 5px;
  @include themedNoEncapsulation() {
    color: t('fare-rules-link-text')
  }
}

.announcements_list li a {
  text-decoration: underline;
}

.width-100 {
  width: 100%;
}

.flight-filters-margin-top {
  margin-top: 40px !important;

  @media(max-width: 850px) {
    margin: 0 !important;
    margin-top: 15px !important;
  }
}

.flight-filters-container {
  margin-top: 85px !important;

  @media(max-width: 850px) {
    margin: 0 !important;
    margin-top: 15px !important;
  }
}

.hotel-filters-margin-top {
  margin-top: 76px !important;

  @media(max-width: 850px) {
    margin-top: 0 !important;
    margin-bottom: 15px;
  }
}

.mobile_toggle_margin {
  @media(max-width: 850px) {
    margin-top: 60px;
  }
}


.display_none {
  display: none !important;
}

app-getmethere-flight-result app-flight-legs * {
  color: white !important;
}

app-getmethere-flight-result app-flight-legs {
  & img {
    filter: invert(1);
  }

  .legs_layover {
    color: white !important;
    background: none !important;
  }
}

app-getmethere-flight-result .leg_button {
  color: white !important;

  &:hover {
    background: none !important;
  }
}

app-carbon-allowance {
  // position: absolute;
  // top: 20px;
}

.sr-only-focusable:focus{
  position: relative;
  height: auto;
  width: auto;
}

.beamer_icon.active {
  left: 16px;
  right: auto !important;
  top: auto!important;
  min-width: 15px!important;
  width: auto !important;
  height: 16px!important;
  background: #dc3545 !important;
  font-family: Open Sans, sans-serif!important;
  line-height: 1 !important;
  border-radius: 50rem;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 10px !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  animation: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media (max-width: 600px) {
    font-size: 11px!important;
    height: 1.5rem!important;
  }
}

.move_basket_icon {
  height: 25px;
  width: 25px;
  top: 30px;
  right: 0;
  position: absolute;
  z-index: 1001;
}


.theme--scion .move_basket_icon{
  filter: invert(1) !important;
}

.padding-15 {
  padding: 15px;
}

.flex-start {
  align-items: flex-start !important;
}

app-datepicker {
  min-width: 50%;
}

::ng-deep .quote-modal > .modal-dialog {
  max-width: 600px;
}

.info-body{
  a{
    display: block;
  }
}

.gmt-container {
  width: 100%;
  max-width: calc(100% - 30px);
  margin: 0 auto;
  padding: 1rem;
  @media(min-width: 1920px) {
    max-width: 1890px;
  }
  @media(max-width: 700px) {
    max-width: 100%;
    flex-direction: column;
  }
  h2 {
    font-weight: bold; 
    margin-top: 10px;
  }
  .beta-container {
    margin-left: 15px;
    padding: 5px 10px;
    @include themedNoEncapsulation() {
      background: t('colour-d');
      color: t('font-secondary');
    }
    border-radius: 25px;
  }
}

.gmt-item{
  padding-top: 15px;
  position: relative;
}

.timeline {
  position: absolute;
  @include themedNoEncapsulation() {
    border-left: t('colour-font-secondary') 3px solid;
  }
  height: calc(100% - 25px);
  top: 42px; // bottom: 82px;
  left: 11px;
}

.timeline-meeting {
  height: 100%;
  top: 0;
}

.last-timelime {
  top: 0!important;
}

.gmt-header{
  width: 100%;
  position: relative;
  top: 4px;
  font-weight: bold;
  & i{
      margin-right: 5px;
  }   
  & span{
      position: relative;
      top: 1px;
  }
  & .ico-taxi{
      position: relative;
      bottom: 1px;
  }
}

.gmt-details{
  padding-left: 32px;
  padding-top: 15px;
}

.gmt-carousel{
  display: flex;
  flex-direction: row;
  @media(max-width: 768px) {
    flex-direction: column;
  }
  &-item{
    display: flex;
    flex-direction: column;
    color: black;
    background: white;
    padding: 15px;
    border-radius: 5px;
    margin-right: 5px;
    border: 3px solid white;
    width: 33%;
    justify-content: space-between;
    flex-direction: column;
    @media(max-width: 768px) {
      width: 100%!important;
    }
    &-active{
        @include themedNoEncapsulation() {
          border: 3px solid t('colour-d');
      }
    }
    .item-description {
      display: flex;
      flex-direction: column;
      .price-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    .item-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      section img{
        margin-right: 1rem;
      }
      button {
        flex: 1;
      }
    }
    & span,button{
        margin-top: 5px;
    }
  }
}

.gmt-fare-rules-link {
  cursor: pointer;
  .gmt-currency-info-icon {
    background: #2f9bff!important;
    margin-right: 0.25rem;
  
    @include themedNoEncapsulation() {
      color: t('colour-b')!important;
    }
  }
}

@media screen and (max-width: 768px) {
  .gmt-carousel {
    flex-direction: column;
    &-item {
      flex: none;
      margin-bottom: 1rem;
      width: 100%;
    }
  }
}

.gmt-footer {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  margin: 2rem 1rem 0 1rem;
}

/*****ANGULAR 17*********/

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  @include themed() {
    color: t('colour-font-secondary')!important;
  }
  font-weight: bold;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  @include themedNoEncapsulation() {
    border-color: t('colour-d')!important;
  }
}

.mat-mdc-tab .mdc-tab__text-label {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary')!important;
  }
  letter-spacing: normal!important;
}

.mat-mdc-radio-button{
  margin-bottom: 5px;
}

.mat-mdc-radio-button .mdc-radio{
  padding: 0!important;
}

.mdc-radio__background{
  vertical-align: middle;
}

.light-primary.mat-mdc-radio-button {
  min-width: 100px;
  display: inline-block;
}

.secondary-text-colour .mdc-label{
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }
}

.secondary-text-colour .mdc-checkbox__background{
  @include themedNoEncapsulation() {
    border-color: t('colour-font-secondary')!important;
  }
}

.light-primary .mdc-checkbox__background{
  @include themedNoEncapsulation() {
    border-color: t('colour-d');
  }
}

.mdc-checkbox{
  padding: 0!important;
  margin-bottom: 5px!important;
  margin-top: 5px!important;
  & .mdc-checkbox__background{
    top: 0!important;
    left: 0!important;
  }
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.mat-mdc-radio-button .mdc-radio__outer-circle {
  @include themedNoEncapsulation() {
    border-color: t('colour-d')!important;
  }
}

.mat-mdc-radio-button .mdc-radio__inner-circle {
  @include themedNoEncapsulation() {
    border-color: t('colour-d')!important;
  }
}

.mdc-checkbox__background {
  @include themedNoEncapsulation() {
    border-color: t('colour-d')!important;
  }
}

.mat-mdc-checkbox-checked.mat-primary .mdc-checkbox__background {
    @include themedNoEncapsulation() {
      border-color: t('colour-d')!important;
      background-color: t('colour-d')!important;
    }
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary');
  }
}

a {
  text-decoration: none;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

.mat-mdc-outlined-button{
  letter-spacing: normal!important;
  white-space: nowrap;
  font-family: Open Sans, sans-serif !important;
}

.row > * {
  width: auto;
}

.mat-mdc-select-panel{
  background: white!important;
  & .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
    background-color: #33766f!important;
  }
  & .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text{
    @include themedNoEncapsulation() {
      color: t('colour-sort-filter');
    }
  }
}



@media(max-width: 700px) {
  .dropdown-menu{
    min-width: 0;
    max-width: calc(100vw - 70px);
    & .dropdown-item{
      white-space: break-spaces;
      height: auto;
      min-height: 38px;
    }
  }
}

/********************************************/

.theme--light .circleRow:hover .circle{
  color: white!important;
}

.theme--light .whiteAlertImg{
  filter: brightness(0.3);
}

.theme--light .others-container{
  background: white!important;
}
.theme--light .busy_train{
  filter: none!important;
}

.theme--scion .busy_train{
  filter: none!important;
}

.hotel-check{
  margin-bottom: 15px;
  position: absolute!important;
  right: 235px;
  z-index: 1;
  bottom: 45px;
  @media(max-width: 700px) {
    position: relative!important;
    right: 0;
    bottom: 0;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}

.sort-options {
  color: #fff;
  @include themedNoEncapsulation() {
    background: t('colour-sort-filter');
  }
  border-top: 1px solid hsla(0, 0%, 100%, 0.3);

  &.mdc-list-item--selected, &:hover{
    @include themedNoEncapsulation() {
      background-color: t('colour-d')!important;
      color: t('colour-b')!important;
    }
  }
}

.currency_info_icon {
  font-weight: 700;
  @include themedNoEncapsulation() {

    background: t('colour-font-secondary');
    color: t('info-icon-colour');
  }
  display: inline-block;
  text-align: center;
  line-height: 15px;
  width: 15px;
  height: 15px;
  border-radius: 8px;
  font-family: Roboto Slab;
  font-style: normal;
  font-size: 9pt;
  cursor: pointer;
  position: relative;
  top: -2px;
}

.currency_info_icon:after {
  content: 'i';
}

.inline-dl {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  flex-wrap: wrap; /* Ensures the dt and dd stay on the same line */
}

.inline-dl dt,
.inline-dl dd {
  display: inline-flex;
  margin: 0;
  align-items: center;
}

.inline-dl dt {
  margin-right: 5px;
  white-space: nowrap; /* Prevents the dt content from wrapping */
}

.inline-dl dd {
  flex-grow: 1; /* Allows dd to take the remaining space */
  white-space: nowrap; /* Prevents the dd content from wrapping */
  overflow: hidden; /* Hides overflow content */
  text-overflow: ellipsis; /* Adds ellipsis (...) to overflowed content */
}

